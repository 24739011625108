/**
 * Created by osirvent on 21/03/2017.
 */
angular
    .module('annexaApp')
    .controller('DocumentsController',['$http', '$scope', '$rootScope', 'HeaderService', '$state', 'Language', '$filter', 'DccumentsFactory', 'RouteStateFactory', '$compile', '$stateParams', 'GlobalDataFactory','AnnexaDocumentActionsButtonFactory','DialogsFactory', 'AnnexaFormlyFactory', 'CustomFieldFactory', 
    function ($http, $scope, $rootScope, HeaderService, $state, Language, $filter, DccumentsFactory, RouteStateFactory, $compile, $stateParams, GlobalDataFactory,AnnexaDocumentActionsButtonFactory, DialogsFactory, AnnexaFormlyFactory, CustomFieldFactory ) {

        //region General

        $scope.languageColumn = Language.getActiveColumn();

        $scope.documentsFactory = DccumentsFactory;

        $scope.statuses = DccumentsFactory.statuses;

        $scope.legend = [];

        _.forEach($linq($scope.statuses).orderBy("x => x.orderStatus").toArray(), function (value) {
            if(value.id) {
                $scope.legend.push({
                    icon: value.icon,
                    style: 'text-' + value.style,
                    name: 'global.documentState.' + value.name
                });
            }
        })

        var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;

        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.doc.documents.all') {
            	$rootScope.headButtons = [];
            	 
            	if($rootScope.app.configuration.load_documents_from_scan_folder.value) {
            		$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.literals.fromScanner', undefined, 'fromScanner').setPermissions('new_document'));
            	}
            	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.literals.addScanner', undefined, 'addScanner').setPermissions('add_scanner'));
            	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.buttons.document.news', undefined, 'newDocuments').setPermissions('new_document'));
            	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.buttons.document.new', undefined, 'newDocument').setPermissions('new_document'));
            	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-download', 'global.buttons.document.downloadDocuments', undefined, 'downloadDocuments'));
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch('#tableFilter')
                ];
            } else if(message.state.name == 'annexa.doc.documents.type') {
            	var documentType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + DccumentsFactory.selectedType.type.id);
            	
            	// intersection de perfil de usuario y los perfiles de documentTypeProfile
                var profiles = $linq(documentType.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                    if(y.profile){
                        return (x.profile.id == y.profile.id);
                    }
                    // en este caso es por si hubiera un null, ya que admitimos todos los perfiles.
                    else{
                        return true;
                    }
                }).toArray();
                
                if((documentType.profiles && documentType.profiles.length == 0) || (profiles && profiles.length > 0)){
                	//Todos pueden crear este tipo de documento o el usuario tiene el perfil para crear el tipo de documento seleccionado, se muestran los botones
                	$rootScope.headButtons = [];
                	
                	if($rootScope.app.configuration.load_documents_from_scan_folder.value) {
                		$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.literals.fromScanner', undefined, 'fromScanner').setPermissions('new_document'));
                	}
                	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.literals.addScanner', undefined, 'addScanner').setPermissions('add_scanner'));
                	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.buttons.document.news', undefined, 'newDocuments').setPermissions('new_document'));
                	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.buttons.document.new', undefined, 'newDocument').setPermissions('new_document'));
                }            	
            	
            	$rootScope.subHeadButtons = [
                    new HeadButtonSearch('#tableFilter')
                ];
            } else if(message.state.name == 'annexa.doc.documents.view') {
            	$rootScope.headButtons = [];
            	
            	if($rootScope.app.configuration.load_documents_from_scan_folder.value) {
            		$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.literals.fromScanner', undefined, 'fromScanner').setPermissions('new_document'));
            	}
            	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.literals.addScanner', undefined, 'addScanner').setPermissions('add_scanner'));
            	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.buttons.document.news', undefined, 'newDocuments').setPermissions('new_document'));
            	$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-plus', 'global.buttons.document.new', undefined, 'newDocument').setPermissions('new_document'));
            	
            	$rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditDocument').setPermissions('admin_audit')
                ];
            	if($rootScope.app.configuration.default_eni_values.show_document_eni){
            		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#documents-list', undefined, 'global.documents.eniDocument', undefined, 'generateENIDocument').setPermissions('generate_document_interoperable'));
            	}
            	
            }

        });

        HeaderService.changeState($state.current);
        
        $scope.generateENIDocument = function(){
            if (DccumentsFactory.document && DccumentsFactory.document.id) {
	            var eniDocumentType = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault(undefined, "x => x.id == "+$rootScope.app.configuration.default_eni_values.eni_document_document_type);
	            if (eniDocumentType) {
	            	DialogsFactory.confirm('global.documents.new.audit.actionType.ENI', 'global.literals.generate_document_ENI')
	                .then(function (data) {
	            		 var saveEniDocument = function(){
	            			 if (this.annexaFormly.form.$valid) {
	            	                var self = this;
	            	                var model = this.annexaFormly.model.modal_body;
	            	                var moduleDoc = 'DOC';
	            	                var document = {
	            	                        createdDate: new Date(),
	            	                        modifyDate: new Date(),
	            	                        dateLastAccess: new Date(),
	            	                        urgent: false,
	            	                        favorite: false,
	            	                        downloaded: false,
	            	                        sentMail: false,
	            	                        type: {id: model.typeSelect},
	            	                        section: {id: model.section},
	            	                        profiles: [],
	            	                        module: moduleDoc,
	            	                        dateDocumentCreation: model.dateDocumentCreation
            	                    };

            	                    _.forEach(model.profiles, function(item) {
            	                        document.profiles.push({ profile: { id: item } });
            	                    });
            	                    if(document.profiles.length == 0 && model.profile){
            	                        document.profiles.push({ profile: { id: model.profile } });
            	                    }
            	                    if (model.archiveClassification && model.archiveClassification.$selected && model.archiveClassification.$selected.id) {
            	                        document.archiveClassification = { id: model.archiveClassification.$selected.id };
            	                    }
            	                    document.sentMail = false;

            	                    var cf = CustomFieldFactory.getModelValues(model);
            	                    document.customFields = [];
            	                    var documentType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + document.type.id);
            	                    if(documentType && cf && cf.length > 0) {
            	                        _.forEach(documentType.customFields, function(docTypeField) {
            	                            var docCustomField = {
            	                                customField: { id: docTypeField.customField.id },
            	                                required: docTypeField.required,
            	                                viewOrder: docTypeField.viewOrder,
            	                                value: docTypeField.value,
            	                                noEditable: docTypeField.noEditable
            	                            };

            	                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");

            	                            if(cfValue) {
            	                                if(docTypeField.customField.frontendType == 'CHECKBOX'){
            	                                    var custom_field_selected = $linq(cf).where(function(x){
            	                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
            	                                    ).toArray();
            	                                    if(custom_field_selected && custom_field_selected.length > 0){
            	                                        docCustomField.value = '[';
            	                                        _.forEach(custom_field_selected, function (item, key) {
            	                                            if(item.value) {
            	                                                if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
            	                                                    var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
            	                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
            	                                                    ).toArray();
            	                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
            	                                                        docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
            	                                                    }
            	                                                }
            	                                            }
            	                                        });
            	                                        docCustomField.value += ']';
            	                                    }
            	                                }else if(Array.isArray(cfValue.value)) {
            	                                    if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
            	                                        docCustomField.value = '[';

            	                                        _.forEach(cfValue.value, function (item) {
            	                                            docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
            	                                        });

            	                                        docCustomField.value += ']';
            	                                    } else {
            	                                        docCustomField.value = cfValue.value[0];
            	                                    }
            	                                } else {
            	                                    if(cfValue.value instanceof Date) {
            	                                        docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
            	                                    } else {
            	                                        docCustomField.value = cfValue.value;
            	                                    }
            	                                }
            	                            }

            	                            document.customFields.push(docCustomField);
            	                        });
            	                    }
            	                    $http({
    			                        url: './api/doc/documentoEni/' +DccumentsFactory.document.id,
    			                        method: 'POST',
    			                        data: JSOG.encode(document)
    			                    }).then(function(data) {
    			                    	$rootScope.loading(false);
    			                    	if(data && data.data && data.data.allRelatedDocuments){
    			                    		DialogsFactory.notify($filter('translate')('global.documents.eniOK'));
    			                    		$rootScope.$broadcast('annexaBoxDocumentsUpdateContentENI', { content: JSOG.decode(data).data.allRelatedDocuments, controller: 'DocumentController'});
    			                    	}else{
    			                    		DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
    			                    	}
    			                    	self.close();
    			                    }).catch(function(error) {
    			                    	DialogsFactory.error( 'global.errors.unknownNew', $filter('translate')('DIALOGS_ERROR_MSG'));
    	                                console.log({msg: 'global.errors.unknownNew'});
    			                    });
	            	            }
	            	                
	            		 } 
	            		 var modal = DccumentsFactory.getOpenNewDocumentModal(undefined, undefined, 'DOC', undefined, undefined, DccumentsFactory.document.archiveClassification, eniDocumentType, undefined, undefined, undefined, [eniDocumentType]);
	            		 modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.disabled = true;
	            		 modal.annexaFormly.fields[0].fieldGroup[1].hideExpression = function(){return true;}
	            		 modal.annexaFormly.fields[0].fieldGroup[2].hideExpression = function(){return true;}
	            		 AnnexaFormlyFactory.showModal('modalEniDocumentNew', modal, saveEniDocument, false, undefined);
	            	}).catch(function(error) {
			        });
	            } else {
	            	DialogsFactory.error('global.literals.generate_document_ENI_KO');
	            }
            }else{
            	DialogsFactory.error('global.literals.generate_document_ENI_KO');
            }
        }
        
        $scope.alerts = [];

        $scope.filterTypes = [];

        $scope.filterData = {
            status: DccumentsFactory.selectedDocumentStatus ? DccumentsFactory.selectedDocumentStatus : '',
            favorite: '',
            loggedUser: $rootScope.LoggedUser.id
        };

        //endregion

        //region Filtre

        $scope.normalFilterColumns = [
            { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
            { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
            { id: 'template', type: 'select', order: 2, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + Language.getActiveColumn()).toArray(), addAll: true, nameProperty: 'description' },
            { id: 'section', type: 'select-tree', order: 3, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn() },
            { id: 'type', type: 'select', order: 4, label: 'global.literals.type', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: $scope.languageColumn },
            { id: 'origin', type: 'select', order: 5, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter: 'DOC' },
            { id: 'createUser', type: 'text', order: 6, label: 'global.literals.user', callAux: true },
            { id: 'meta', type: 'text', order: 7, label: 'global.literals.meta', callAux: true },
            { id: 'modifyDdate', type: 'dateRange', order: 8, label: 'global.literals.modified', callAux: true },
            { id: 'digitalized', type: 'checkbox', order: 9, label: 'global.documents.onlyScanned', callAux: true},
            { id: 'internalDoc', type: 'checkbox', order: 10, label: 'global.documents.internalDocument', callAux: true}
        ]


        //endregion

        //region Taula

        var docStatusTitle = function() {
            var content = '';

            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
            content += '            <span class="fa-stack">';
            content += '                <i class="fa fa-{{headerIconDocStatus(true)}} text-{{headerIconDocStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="status in statuses | orderBy:\'orderStatus\'" ng-click="setDocumentTypeDT(status);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{status.icon}} text-{{status.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="status.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="global.documentState.{{status.name}}" ng-if="status.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }

        var docStatusRender = function(data, type, full, meta) {
            var content = '';

            if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
                var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                if(status) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title=" ' + $filter('translate')('global.documentState.' + status.name) + '">';
                    content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }

        var favoriteTitle = function() {
            var content = '';

            content += '<div class="btn-group dropdown">';
            content += '    <button class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
            content += '        <span class="sr-only">{{ \'global.literals.showOptions\' | translate }}</span>';
            content += '        <span class="fa-stack">';
            content += '            <i ng-show="headerIconFavorite(\'all\');" class="fa fa-minus text-lg fa-stack-1x"></i>';
            content += '            <i ng-show="headerIconFavorite(\'favorite\');" class="fa fa-star text-warn text-lg fa-stack-1x"></i>';
            content += '            <i ng-show="headerIconFavorite(\'noFavorite\');" class="fa fa-star-o text-lg fa-stack-2x"></i>';
            content += '        </span>';
            content += '    </button>';
            content += '    <div class="dropdown-menu">';
            content += '        <a class="dropdown-item" ng-click="setFavorite(\'all\');">';
            content += '            <span class="fa-stack" aria-hidden="true">';
            content += '                <i class="fa fa-minus text-sm"></i>';
            content += '            </span>';
            content += '            {{\'global.literals.all\' | translate}}';
            content += '        </a>';
            content += '        <a class="dropdown-item" ng-click="setFavorite(\'favorite\');">';
            content += '            <span class="fa-stack" aria-hidden="true">';
            content += '                <i class="fa fa-star text-warn text-lg fa-stack-1x"></i>';
            content += '            </span>';
            content += '            {{\'global.literals.favorite\' | translate}}';
            content += '        </a>';
            content += '        <a class="dropdown-item" ng-click="setFavorite(\'noFavorite\');">';
            content += '            <span class="fa-stack" aria-hidden="true">';
            content += '                <i class="fa fa-star-o text-lg fa-stack-2x"></i>';
            content += '            </span>';
            content += '            {{\'global.literals.noFavorite\' | translate}}';
            content += '        </a>';
            content += '    </div>';
            content += '</div>';

            return content;
        }

        var favoriteRender = function (data, type, full, meta) {
            var starClasses = 'fa fa-star text-warn text-lg fa-stack-1x';

            if(!data) {
                starClasses = 'fa fa-star-o text-lg fa-stack-2x';
            }
            var content = '';
            content += '<div class="text-center">';
            content += '    <button class="md-btn p-a-0 md-flat md-btn__star" ng-click="updateFavorite(\'' + full.id + '\', '+full.favorite+')" title="{{ \'global.literals.favorite\' | translate }}">';
            content += '        <span class="sr-only">{{ \'global.literals.favorite\' | translate }}</span>';
            content += '        <span class="fa-stack">';
            content += '            <i class="' + starClasses + '"></i>';
            content += '        </span>';
            content += '    </button>';
            content += '</div>';

            return content;
        }

        var actionsTitle = function () {
            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
        }

        var actionsRender = function (data, type, full, meta) {
            return '<annexa-document-actions-button document="tableObjects[' + full.id + ']" list="true"></annexa-document-actions-button>';
        }

        var datatableSettings = $rootScope.app.configuration.datatables_settings;

        var tableColumnsNormal = [
            { id: 'createdDate', visible: false, title:'NotShow' },
            { id: 'docStatus', width: '53px', title: docStatusTitle(), render: docStatusRender, sortable: false },
            { id: 'favorite', width: '53px', title: favoriteTitle(), render: favoriteRender, sortable: false },
            { id: 'type', width: '40px', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', $scope.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
            { id: 'id', width: '100px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.id; if(full.createdDate) { code = new Date(full.createdDate).getFullYear().toString().substr(-2) + full.id; } code;') },
            { id: 'name', column: new UIRouterStateColumn($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[full.id]\'})', 'name')  },
            { id: 'dossierTransactionDocuments', column: new DossierTransactionColumn($scope, $filter, 'global.literals.trams','', 'dossierTransactionDocuments', 'dossierTransactionsComponent'), sortable: false },
            { id: 'createUser', column: new UserColumn($filter, 'global.literals.user') },
            { id: 'modifyDate', width: '140px', column: new DateTimeColumn($filter, 'global.literals.modifyDate') },
            { id: 'actions', columnName: 'id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender, sortable: false}
        ];

        $scope.normalTableColumns = getDatatableColumnsSettings(datatableSettings, 'datatable_documents', tableColumnsNormal);

        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_documents', tableColumnsNormal);

        $scope.tableOrderProperties = {sortName: 'modifyDate', sort: [[8,'desc']]};

        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0){
            $scope.tableOrderProperties = {
                sortName: orderProperties.name,
                sort: [[orderProperties.index,orderProperties.direction]]
            };
        }

        $scope.headerIconDocStatus = function(icon) {
            if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0 && $scope.filterData.status) {
                var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.status + "'");

                if(status) {
                    if(icon) {
                        return status.icon;
                    } else {
                        return status.style;
                    }
                }
            }

            if(icon) {
                return 'minus';
            } else {
                return 'false';
            }
        }

        $scope.setDocumentTypeDT = function(status){
            $scope.filterData.status = ((!status.id || status.id == 0) ?  '' : status.id);
            if($scope.tableDefinition){
                $scope.tableDefinition.reloadInternalData(true, true);
            }else{
                var scopeAux = angular.element('#documents-list').scope();
                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                    scopeAux.tableDefinition.reloadInternalData(true, true);
                }
            }
        }
        $scope.headerIconFavorite = function(value) {
            if(value == 'favorite' && $scope.filterData.favorite == 'true') {
                return true;
            } else if (value == 'noFavorite' && $scope.filterData.favorite == 'false') {
                return true;
            } else if (value == 'all' && $scope.filterData.favorite == '') {
                return true;
            }

            return false;
        }

        $scope.setFavorite = function(type) {
            switch (type) {
                case 'favorite':
                    $scope.filterData.favorite = 'true';
                    break;
                case 'noFavorite':
                    $scope.filterData.favorite = 'false';
                    break;
                default:
                    $scope.filterData.favorite = '';
                    break;
            }
            if($scope.tableDefinition){
                $scope.tableDefinition.reloadInternalData(true);
            }else{
                var scopeAux = angular.element('#documents-list').scope();
                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                    scopeAux.tableDefinition.reloadInternalData(true);
                }
            }
        }

        $scope.updateFavorite = function(id, favorite) {
            var favoriteAux = true;
            if(favorite){
                favoriteAux = false;
            }
            DccumentsFactory.updateFavorite(id, favoriteAux)
                .then(function (data) {
                    if($scope.tableDefinition){
                        $scope.tableDefinition.reloadInternalData(false, true);
                    }else{
                        var scopeAux = angular.element('#documents-list').scope();
                        if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                            scopeAux.tableDefinition.reloadInternalData(false, true);
                        }
                    }
                });
        };

        //endregion

        //region Folders

        $scope.normalFolderColumns = [
            { id: 'code', label: 'global.literals.code', type: 'text', column_type: 'NORMAL'  },
            { id: 'createUser', label: 'global.literals.user', type: 'user', column_type: 'NORMAL'  },
            { id: 'modifyDdate', label: 'global.literals.modifyDate', type: 'date', column_type: 'NORMAL'  }
        ]

        //endregion

        //region Mètodes

        $scope.newDocument = function () {
            DccumentsFactory.openNewDocument(undefined, undefined, undefined, undefined, undefined, undefined, DccumentsFactory.selectedType.type);
        };
        
        $scope.downloadDocuments = function () {
        	DccumentsFactory.openDownloadDocuments();
        };

        var preCloseModalFunction = function () {
            if($state.current.name == 'annexa.doc.documents.all' || $state.current.name == 'annexa.doc.documents.type') {
                $state.reload();
            }else{
                $scope.backToList();
            }
        };

        $scope.newDocuments = function () {
            var self = this;

            DccumentsFactory.openMasiveDocumentModalFolderFromDoc(preCloseModalFunction, self, undefined, undefined, DccumentsFactory.selectedType.type);
        };
        
        $scope.addScanner = function () {
            var self = this;

            DccumentsFactory.openMasiveDocumentModalAddScann(preCloseModalFunction, self, undefined, undefined, DccumentsFactory.selectedType.type);
        };

        $scope.fromScanner = function () {
            var self = this;

            DccumentsFactory.openMasiveDocumentModalScan(preCloseModalFunction, self, undefined, undefined, DccumentsFactory.selectedType.type);
        };
        $scope.auditDocument = function () {
            if (DccumentsFactory.document && DccumentsFactory.document.id) {
                window.open($state.href('annexa.admin.audit_documents', {"objectId": DccumentsFactory.document.id}), '_blank');
            }
        };
        $scope.backToList = function () {
            var fromState = $rootScope.previousState;
            var fromParams = $rootScope.previousStateParams;

            if(fromState && fromState.name && fromParams && fromParams.type) {
                $state.transitionTo(fromState.name, { "type" : fromParams.type});
            } else {
                $state.transitionTo('annexa.doc.documents.all');
            }

        };

        //endregion


    }])
    .controller('DocumentsTypeController',['$scope', '$state', 'DccumentsFactory', '$rootScope', '$stateParams', 'TableFilterFactory', '$filter', '$timeout', 'Language', 'DocumentsViewModesFactory', '$localStorage', 'RouteStateFactory', 'GlobalDataFactory', function($scope, $state, DccumentsFactory, $rootScope, $stateParams, TableFilterFactory, $filter, $timeout, Language, DocumentsViewModesFactory, $localStorage, RouteStateFactory, GlobalDataFactory) {

        //region General

        $state.current.data.displayName = DccumentsFactory.type[$scope.languageColumn];

        var routeStateColumnsFilter = RouteStateFactory.getRouteState($state.current, 'ColumnsFilter');
        if(routeStateColumnsFilter) {
        	if(!$scope.filterData){
                $scope.filterData = {};
            }
            $scope.filterData.status = routeStateColumnsFilter.state.status;
            $scope.filterData.favorite = routeStateColumnsFilter.state.favorite;
            $scope.filterData.loggedUser = routeStateColumnsFilter.state.loggedUser;
        }

        var documentsByType = $rootScope.app.configuration.documents_by_type;
        $scope.isDocumentsByType = false;
        $scope.showList = false;
        $scope.showFolders = false;

        //endregion

        //region Document View Modes

        $scope.showViewModes = $rootScope.app.configuration.view_modes.value;

        $scope.viewModes = new DocumentsViewModeDefinition(GlobalDataFactory.documentViewModeTypes, -1);
        if(!$scope.viewModes || !$scope.viewModes.types || $scope.viewModes.types.length == 0){
            $scope.viewModes = new DocumentsViewModeDefinition(GlobalDataFactory.documentViewModeTypes, -1);
        }
        $scope.viewModes.types = $linq($scope.viewModes.types).where("x => x.documentType && x.documentType.id == " + $stateParams.type).toArray();

        var setting = $rootScope.app.name + '-ActiveViewMode';

        if (angular.isDefined($localStorage[setting]) && $localStorage[setting] != -1) {
            var indexOfActive = $linq($scope.viewModes.types).indexOf("x => x.id == " + $localStorage[setting]);

            if(indexOfActive != -1) {
                $scope.viewModes.active = $localStorage[setting];
            }
        } else {
            if($scope.viewModes && $scope.viewModes.types && $scope.viewModes.types[0] && $scope.viewModes.types[0].id){
                $localStorage[setting] = $scope.viewModes.types[0].id;
                $scope.viewModes.active = $localStorage[setting];
            }else{
                $localStorage[setting] = $scope.viewModes.active;
            }
        }

        $scope.$on('documentsViewModeChange', function(event, args) {
            if (args.id == -1) {
                $scope.viewModes.showDefault = true;

                if($scope.tableDefinition.reloadInternalData) {
                    $scope.tableDefinition.reloadInternalData(true);
                }

            } else {
                $scope.viewModes.showDefault = false;
            }

            $localStorage[setting] = args.id;
        });

        $rootScope.$on('documentActionButtonExecuted', function(event, args) {
            if($scope.viewModes.showDefault){
                if(args.button == 'complete' || args.button == 'sendToSign' || args.button == 'returnToDraft' ) {
                    $scope.tableDefinition.reloadInternalData(false);
                }else if(args.button == 'delete'){
                    $scope.tableDefinition.reloadInternalData(true);
                }
            }
        });

        $rootScope.$on('SendToSignModalFinished', function (event) {
            $scope.tableDefinition.reloadInternalData(false);
        })

        //endregion

        //region Filtre

        $scope.filter = [];

        //endregion

        //region Documents per tipus

        if(documentsByType.active == 'true') {
            var selectedType = $linq(documentsByType.docTypes).singleOrDefault(undefined, "x => x.type.id == " + $stateParams.type);

            if(selectedType) {
                $scope.isDocumentsByType = true;
                if (selectedType.tableColumns) {
	                var statusColumn = $linq(selectedType.tableColumns).where("x => x.id == 'docStatus'").toArray();
	                if (statusColumn.length == 0) {
	                	//Si no se encuentra la columna de estado del documento se elimina la leyenda con los iconos de cada estado
	                	$scope.legend = [];
	                }
                }
                $scope.showList = selectedType.showList;
                //TODO: En la optimització s'ha decidit de no fer la part de carpeta
                $scope.showFolders = selectedType.showFolders;
                //$scope.showFolders = false;

                if($scope.showFolders) {
                    var folderViewModes = $linq($scope.viewModes.types).where("x => x.documentType && x.documentType.id == " + selectedType.type.id).toArray();
                    $scope.viewModes.types = folderViewModes;

                    if(folderViewModes.length == 0) {
                        $scope.showFolders = false;
                    }
                }

                $scope.showViewModes = $scope.showFolders;

                //region Columnes filtre

                var indexFilterType = $linq($scope.filterTypes).indexOf("x => x.type == " + selectedType.type.id)
                if(indexFilterType == -1) {
                    var filterType = {
                        type: selectedType.type.id,
                        filter: []
                    };

                    var order = 1;

                    _.forEach(selectedType.filterColumns, function (column) {
                        if (column.type == 'NORMAL') {
                            var normalColumn = $linq($scope.normalFilterColumns).singleOrDefault(undefined, "x => x.id == '" + column.id + "'");

                            if (normalColumn) {
                                normalColumn.order = order;
                                filterType.filter.push(normalColumn);
                            }
                        } else {
                            var customColumn = $linq(GlobalDataFactory.customFields).singleOrDefault(undefined, "x => x.id == " + column.id);

                            if (customColumn) {
                                filterType.filter.push(TableFilterFactory.getCustomFieldFilter(customColumn, order));
                            }
                        }

                        order++;
                    });

                    $scope.filterTypes.push(filterType);
                    $scope.filter = $scope.filterTypes[$scope.filterTypes.length-1].filter;
                } else {
                    $scope.filter = $scope.filterTypes[indexFilterType].filter;
                }
                
                var routeState = RouteStateFactory.getRouteState($state.current);
                if(routeState) {
                    $scope.filter = routeState.state;
                }
                //endregion

                //region Columnes Taula

                //region Taula

                var getFilterCall = function () {
                    var filterCall = TableFilterFactory.getFilterCall($scope.filter);

                    if(documentsByType.active == 'true') {
                        filterCall.type = { id: $stateParams.type };
                    }

                    if($scope.filterData.status) {
                        filterCall.docStatus = $scope.filterData.status;
                    }

                    if($scope.filterData.favorite) {
                        filterCall.favorite = $scope.filterData.favorite;
                    }

                    RouteStateFactory.addRouteState($state.current, $scope.filterData, 'ColumnsFilter');

                    return filterCall;
                }

                var getFilterCallAux = function () {
                    var filterCallAux = TableFilterFactory.getFilterCallAux($scope.filter);

                    filterCallAux.loggedUser = $rootScope.LoggedUser.id;

                    return filterCallAux;
                }

                $scope.tableDefinition = {
                    id: 'tableDocs',
                    origin: 'doc',
                    objectType: 'Document',
                    sortName: $scope.tableOrderProperties.sortName,
                    sort: $scope.tableOrderProperties.sort,
                    filterCall: getFilterCall(),
                    filterCallAux: getFilterCallAux(),
                    filterCallFunc: getFilterCall,
                    filterCallAuxFunc: getFilterCallAux,
                    columns: [],
                    containerScope: $scope,
                    useTableObjects:true
                }

                //endregion

                var sortName = '';
                var addedNormalColumn = false;

                _.forEach(selectedType.tableColumns, function (column) {
                    if (column.type == 'NORMAL') {
                    	var columnsOk = angular.copy($scope.normalTableColumns);
                    	columnsOk.push( { id: 'code', width: '100px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[full.id]\'})', undefined, 'var code = full.id; if(full.createdDate) { code = new Date(full.createdDate).getFullYear().toString().substr(-2) + full.id; } code;') });
                        var normalColumn = $linq(columnsOk).singleOrDefault(undefined, "x => x.id == '" + column.id + "'");

                        if (normalColumn) {
                            if (!sortName) {
                                sortName = normalColumn.id + ',asc';
                            }
                            if (normalColumn.id == 'createdDate') {
                                delete normalColumn.visible;
                                normalColumn.width = '140px';
                                normalColumn.column = new DateTimeColumn($filter, 'global.literals.creation_date');
                            }

                            $scope.tableDefinition.columns.push(normalColumn);
                            addedNormalColumn = true;
                        }
                    } else {
                        var customColumn = $linq(GlobalDataFactory.customFields).singleOrDefault(undefined, "x => x.id == " + column.id);

                        if(customColumn) {
                            var tableCustomColumn = { id: customColumn.templateTag, column: new CustomFieldColumn(customColumn, Language.getActiveColumn()), sortable: false }

                            $scope.tableDefinition.columns.push(tableCustomColumn);
                        }
                    }
                });

                var actionsColumn = $linq($scope.normalTableColumns).singleOrDefault(undefined, "x => x.id == 'actions'");

                if (actionsColumn) {
                    $scope.tableDefinition.columns.push(actionsColumn);
                }

                if(!addedNormalColumn) {
                    $scope.tableDefinition.columns.unshift({ id: 'createdDate', visible: false })
                    $scope.tableDefinition.sort = [[0, 'asc']];
                    $scope.tableDefinition.sortName = 'createdDate,desc';

                } else {
                    $scope.tableDefinition.sort = [[0, 'asc']];
                    $scope.tableDefinition.sortName = sortName;
                }


                //endregion

                //region Columnes Folders

                _.forEach(selectedType.folderColumns, function(column) {
                    if(column.type == 'NORMAL') {
                        var normalColumn = $linq($scope.normalFolderColumns).singleOrDefault(undefined, "x => x.id == '" + column.id + "'");

                        if(normalColumn) {
                            $scope.viewModes.columns.push(normalColumn);
                        }
                    } else {
                        var customColumn = $linq(GlobalDataFactory.customFields).singleOrDefault(undefined, "x => x.id == " + column.id);

                        if(customColumn) {
                            var folderColumn = { id: customColumn.templateTag, label: customColumn[$scope.languageColumn], column_type: 'CUSTOM', customColumn: customColumn };

                            $scope.viewModes.columns.push(folderColumn);
                        }
                    }
                })

                //endregion
            }
        } else {
            $scope.viewModes.columns.push({ id: 'modifyDate', label: 'global.literals.lastModification', type: 'date', column_type: 'NORMAL' });
        }

        //endregion
    }])
    .controller('DocumentsAllController',['$scope', '$rootScope', 'HeaderService', '$state', '$stateParams', 'TableFilterFactory', 'DccumentsFactory', 'RouteStateFactory', function($scope, $rootScope, HeaderService, $state, $stateParams, TableFilterFactory, DccumentsFactory, RouteStateFactory) {

        //region General

        var routeStateColumnsFilter = RouteStateFactory.getRouteState($state.current, 'ColumnsFilter');

        if(routeStateColumnsFilter) {
            if(!$scope.filterData){
                $scope.filterData = {};
            }
            $scope.filterData.status = routeStateColumnsFilter.state.status;
            $scope.filterData.favorite = routeStateColumnsFilter.state.favorite;
            $scope.filterData.loggedUser = routeStateColumnsFilter.state.loggedUser;
            if(DccumentsFactory.selectedDocumentStatus){
                $scope.filterData.status = DccumentsFactory.selectedDocumentStatus;
            }
        }

        $rootScope.$on('documentActionButtonExecuted', function(event, args) {
            if(args.button == 'complete' || args.button == 'sendToSign' || args.button == 'returnToDraft' ) {
                $scope.tableDefinition.reloadInternalData(false, true);
            }else if(args.button == 'delete'){
                $scope.tableDefinition.reloadInternalData(true, true);
            }
        });

        $rootScope.$on('SendToSignModalFinished', function (event) {
            $scope.tableDefinition.reloadInternalData(false, true);
        })

        //endregion

        //region Filtre

        $scope.filter = $scope.normalFilterColumns;

        var routeState = RouteStateFactory.getRouteState($state.current);

        if(routeState) {
            $scope.filter = routeState.state;
        }

        //endregion

        //region Taula

        var getFilterCall = function () {
            var filterCall = TableFilterFactory.getFilterCall($scope.filter);

            if($scope.filterData.status) {
                filterCall.docStatus = $scope.filterData.status;
            }

            if($scope.filterData.favorite) {
                filterCall.favorite = $scope.filterData.favorite;
            }

            RouteStateFactory.addRouteState($state.current, $scope.filterData, 'ColumnsFilter');

            return filterCall;
        }

        var getFilterCallAux = function () {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.filter);

            filterCallAux.loggedUser = $rootScope.LoggedUser.id;

            return filterCallAux;
        }

        $scope.tableDefinition = {
            id: 'tableDocs',
            origin: 'doc',
            objectType: 'Document',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: angular.copy($scope.normalTableColumns),
            containerScope: $scope,
            useTableObjects: true,
            callOrigin: 'documents'
        }

        //endregion

    }])
    .controller('DocumentViewController',[function() {
        //Empty
    }]);